// log
import store from "../store";
import Web3 from "web3";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
        let paused = await store
        .getState()
        .blockchain.smartContract.methods.paused()
        .call();
      let balance = await store
        .getState()
        .blockchain.web3.eth.getBalance(CONFIG.CONTRACT_ADDRESS);
      balance = Web3.utils.fromWei(balance.toString(), 'ether');
      balance = Math.round(balance*100)/100;
      dispatch(
        fetchDataSuccess({
          totalSupply,
          balance,
          paused
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
